/**
 *
 * IndexPage
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { graphql } from "gatsby";

import OnTour from 'components/sections/OnTour';



const OnTourPage = ({ data }) => {
  useEffect(() => {

  });

  const events = data.events['nodes'];
  
  return (
    <Container>
      <OnTour events={events} onOnTourPage={true} />
    </Container>
  );
};

OnTourPage.propTypes = {

};

export default OnTourPage;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;

export const pageQuery = graphql`
  {
    events: allCraftOnTourOnTourEntry(sort: {order: ASC, fields: startDate}) {
      nodes {
        ... on Craft_onTour_onTour_Entry {
          title
          locationName
          startDate
          endDate
          eventLink
        }
      }
    }
  }
`;